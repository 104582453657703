import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";


const JobApply = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const jobId = params.get("id");
  const [job, setJob] = useState([]);
  const isMountedRef = useRef(false);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [cover_letter, setCover_letter] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isFocused, setIsFocused] = useState('');

  const gContext = useContext(GlobalContext);
  const { user } = useContext(GlobalContext);

  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  const fetchJobDetails = async () => {
    try {
      const response = await axiosConfig.post(`/jobdetails-forweb/${jobId}`, { userId: userId });
      if (response.data.success && isMountedRef.current) {
        setJob(response.data.data);
        console.log('This is the fetched job details:', response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setJob([]);
        }
        console.error('Failed to fetch job details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setJob([]);
      }
      console.error('Error fetching job details:', error);
    }
  };


  useEffect(() => {
    if (!jobId) {
      console.log('Waiting for the job id');
      return;
    }

    isMountedRef.current = true;

    fetchJobDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [jobId, userId]);


  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };


  const handleJobApply = async (e) => {
    e.preventDefault();

    try {

      if (!userId) {
        gContext.toggleSignInModal();

        return
      }

      setErrors({});

      if (userId && jobId) {

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('job_id', jobId);
        formData.append('cover_letter', cover_letter);

        const response = await axios.post(`${network.serverip}/api/job-apply-web`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });

        console.log('This is the response:', response);

        if (response.data.success && isMountedRef.current) {

          setJob(prevState => ({
            ...prevState,
            isApplied: prevState.isApplied === 1 ? 0 : 1,
          }));

          handleSuccessMessage(response.data.message);
          setCover_letter('');
          console.log('Job applied successfully');

        } else {
          if (isMountedRef.current) {
            console.log('Failed to apply');
          }
          console.error('Failed to save / unsave job:', response.data.message);
        }
      } else {
        console.error('userId or jobId is missing');
      }
    } catch (error) {
      console.error('Error applying job:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            applyJobError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          applyJobError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const styles = JobApplyStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/joblisting');
                    }
                  }}
                    className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
              {/* <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0"> */}
              <div className="col-xl-12 col-lg-12">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-12 text-center">
                        <p className="font-size-4 text-success min-height-40">
                          {successMessage || ''}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center">
                          <div className="square-72 d-flex align-items-center mr-8" style={styles.imageContainer}>
                            <img
                              src={job && job.image ? imageBaseUrl + job.image : ""}
                              alt=""
                              style={styles.jobImage}
                            />
                          </div>
                          <div>
                            <h3 className="font-size-6 mb-0">
                              {job?.title || ''}
                              {job?.featured_job === 1 ? ' (Featured Job)' : ''}
                            </h3>
                            <span className="font-size-3 text-gray line-height-2">
                              {job?.institution?.title || ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                        <div className="media justify-content-md-end">
                          <p className="font-size-4 text-gray mb-0">
                            {job?.created_at ?
                              new Date(job.created_at).toLocaleString('default', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric'
                              })
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleJobApply}>
                    <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                      <div className="form-group">
                        <label
                          htmlFor="cover_letter"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Cover letter (Optional):'}
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter your cover letter"
                          id="cover_letter"
                          name="cover_letter"
                          value={cover_letter}
                          onChange={(e) => {
                            if (e.target.value.length <= 2000) {
                              setCover_letter(e.target.value);
                              e.target.style.height = 'auto';
                              e.target.style.height = `${e.target.scrollHeight}px`;
                            }
                          }}
                          rows="4"
                          onFocus={() => setIsFocused('cover_letter')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'cover_letter' ? styles.focussdedInput : {},
                            overflow: 'hidden',
                            resize: 'none'
                          }}
                          maxLength={2000}
                        />
                        <p
                          className="font-size-4 text-dodger d-flex justify-content-end"
                        >
                          {`${cover_letter?.length || 0}/${2000}`}
                        </p>
                        {errors?.cover_letter ? <p className="font-size-3 text-danger">
                          {errors.cover_letter}
                        </p> : null}
                      </div>
                    </div>

                    <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                          {job?.isApplied === 1 ? (
                            <button
                              type="button"
                              className="btn btn-gray text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6" disabled>
                              {"Applied"}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-green text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6"
                            >
                              {"Apply"}
                            </button>
                          )}
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.applyJobError ? <p className="font-size-3 text-danger mt-2">
                            {errors.applyJobError}
                          </p> : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default JobApply;

function JobApplyStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    imageContainer: {
      backgroundColor: '#f5f5f5',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
    },
    jobImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    skillItem: {
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      maxWidth: '100%',
    },

    focussdedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }

  };

  return styles;
}

